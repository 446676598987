const defaultDevice = () => ({
  name: '',
  account: '',
  password: '',
  user: {},
  phone: {},
  ip: '',
  brand: '',
  model: '',
  mac: '',
  vars: [],
});

export default defaultDevice;
