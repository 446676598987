const queue = () => ({
  name: '', // required
  description: '',
  priority: 0,
  dncList: {},
  calendar: {}, // required
  variables: [],
});

export default queue;
