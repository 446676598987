import {
  getDefaultGetListResponse,
  getDefaultGetParams,
} from '@webitel/ui-sdk/src/api/defaults';
import applyTransform, {
  camelToSnake, 
  merge, mergeEach, notify, snakeToCamel,
  starToSearch,
} from '@webitel/ui-sdk/src/api/transformers';
import axios from 'axios';
import { MediaFileServiceApiFactory } from 'webitel-sdk';
import instance from '../../../../../app/api/instance';
import configuration from '../../../../../app/api/openAPIConfig';

const mediaService = new MediaFileServiceApiFactory(configuration, '', instance);

const token = localStorage.getItem('access-token');
const baseUrl = process.env.VUE_APP_API_URL;

const getMediaList = async (params) => {
  const {
    page,
    size,
    search,
    sort,
    fields,
    id,
  } = applyTransform(params, [
    merge(getDefaultGetParams()),
    starToSearch('search'),
  ]);

  try {
    const response = await mediaService.searchMediaFile(
      page,
      size,
      search,
      sort,
      fields,
      id,
    );
    const { items, next } = applyTransform(response.data, [
      snakeToCamel(),
      merge(getDefaultGetListResponse()),
    ]);
    return {
      items,
      next,
    };
  } catch (err) {
    throw applyTransform(err, [
      
      notify,
    ]);
  }
};

const getMedia = async ({ itemId }) => {
  const url = `${baseUrl}/storage/media/${itemId}/stream?access_token=${token}`;
  try {
    return await instance.get(url);
  } catch (err) {
    throw applyTransform(err, [
      
      notify,
    ]);
  }
};

export const downloadMedia = async (id) => {
  const url = `${baseUrl}/storage/media/${id}/download?access_token=${token}`;
  try {
    return await instance.get(url);
  } catch (err) {
    throw applyTransform(err, [
      
      notify,
    ]);
  }
};

const addMediaInstance = axios.create({
  headers: {
    'content-type': 'multipart/form-data',
  },
});

const addMedia = async (params) => {
  const url = `${baseUrl}/storage/media?access_token=${token}`;

  const formData = new FormData();
  formData.append('file', params.itemInstance);
  try {
    const response = await addMediaInstance.post(url, formData);
    applyTransform(response, [
      notify(() => ({ type: 'info', text: 'Successfully added' })),
    ]);
    return response;
  } catch (err) {
    throw applyTransform(err, [
      
      notify,
    ]);
  }
};

const deleteMedia = async ({ id }) => {
  try {
    const response = await mediaService.deleteMediaFile(id);
    return applyTransform(response.data, []);
  } catch (err) {
    throw applyTransform(err, [
      
      notify,
    ]);
  }
};

const getMediaLookup = (params) => getMediaList({
  ...params,
  fields: params.fields || ['id', 'name'],
});

const MediaAPI = {
  getList: getMediaList,
  get: getMedia,
  add: addMedia,
  delete: deleteMedia,
  getLookup: getMediaLookup,
};

export default MediaAPI;
