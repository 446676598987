import { EngineCommunicationChannels } from 'webitel-sdk';

export default {
  auth: {
    register: 'Жасау',
    login: 'Кіру',
    registerReset: 'Құпиясөзді жіберу/құпиясөзді алып тастау ',
    detailsSubtitle: 'Деректеріңізді енгізіңіз',
    resetPasswordLink: 'Құпиясөзіңізді ұмыттыңыз ба?',
    user: 'Пайдаланушының аты',
    password: 'Құпиясөз',
    confirmPassword: 'Құпиясөзді қайталау',
    key: 'Лицензия кілті',
    registerSubmit: 'Тіркелу',
    resetSubmit: 'Бас тарту',
    loginSubmit: 'Кіру',
    carousel: {
      title1: 'Бұлт және Он-сайтқа',
      text1: 'Қауіпсіздік саясатыңыз деректерді сақтауға және бұлттық қызметтерді пайдалануға мүмкіндік бермейді ме? Webitel көмегімен сіз өз сайтыңызда байланыс орталығын құра аласыз!',

      title2: 'Көп арналы және ортақ арналы',
      text2: 'Клиенттермен әрқашан, кез келген жерде байланыста болыңыз! Кез келген күрделі IVR мәзірі,  кіріс, шығыс қарым-қатынас науқандары немесе әлеуметтік желілерге  хабарламалар - енді тіпті оңай!',

      title3: 'Орнықты жұмыс істейтін инфрақұрылым',
      text3: 'Енді ешқандай тұрып қалу болмайды және деректер жоғалмайды. Жылдам ауқымдау және орнықты жұмыс істейтін архитектура әр түрлі байланыс арналарынан минутына клиенттердің  1000-нан астам өтінішіне бір уақытта қызмет көрсетуге мүмкіндік береді.',

      title4: 'Дизайн',
      text4: 'Барлық қажетті функцияларға жылдам қол жеткізуге мүмкіндік беретін ең ыңғайлы басқару панелі бар жаңа минималистік интерфейс. Аз клик - көп мүмкіндіктер.',

      title5: 'Оператор топтарымен жұмыстың жаңа модулі',
      text5: 'Қоңырауларды барынша тиімді тарату. Оператордың әртүрлі науқандарға қатысу үшін бірнеше дағдылары болуы мүмкін. Клиентке тек кәсіби мамандар қызмет көрсетеді.',

      title6: 'Қол жеткізу құқықтарын күйге келтіру',
      text6: 'Құқықтарды басқарудың жаңа модулі – бақылаудың жаңа мүмкіндіктері. Сіз енгізілген өзгерістерді қадағалай отырып, тек бөлімдерге ғана емес, пайдаланушылардың әрекеттеріне де құқық бере аласыз.',
    },
  },
  header: {
    logout: 'Шығу',
    docs: 'Анықтама',
  },
  appNavigator: {
    title: 'Webitel қосымшалары',
  },
  settings: {
    settings: 'Баптау',
    changePassword: 'Құпиясөзді өзгерту',
    language: 'Тіл',
    webPhone: 'Web-телефон',
    useWebPhone: 'Web-телефонды пайдаланушы',
    useStun: 'STUN пайдаланушы',
  },
  validation: {
    email: 'Электрондық пошта мекенжайын енгізіңіз',
    required: 'Міндетті түрде толтырылатын өріс',
    sameAs: 'Құпия сөз қате',
    minValue: 'мәні кем дегенде .....болуы керек',
    maxValue: 'Мәні тым үлкен болмауы керек',
    numeric: 'Сандар болуы керек',
    requiredArrayValue: 'Өріс бос болмауы керек',
    isPositiveValue: 'Мәні нөлден үлкен болуы керек',
    cron: 'қате cron- өрнегі',
  },
  nav: {
    directory: {
      directory: 'Каталог',
    },
    routing: {
      routing: 'Бағыттау',
    },
    lookups: {
      lookups: 'Анықтамалар',
    },
    ccenter: {
      ccenter: 'Байланыс орталығы',
    },
    integrations: {
      integrations: 'Интеграциялар',
    },
    permissions: {
      permissions: 'Рұқсат',
    },
  },
  home: {
    dashboard: 'Есептер',
    welcomeText: 'Қош келдіңіз!',
    news: 'Жаңалықтар',
  },
  objects: {
    all: 'Барлығы { entity }',
    administration: 'Басқару ',
    general: 'Жалпы',
    new: 'Жаңа нысан',
    add: 'Қосу',
    create: 'Жасау',
    read: 'Оқу',
    edit: 'Редакциялау',
    delete: 'Жою',
    save: 'Сақтау',
    saved: 'Сақталған',
    saveAs: 'Жаңа ретінде сақтаңыз',
    close: 'Жабу',
    next: 'Әрі қарай',
    back: 'Артқа',
    change: 'Ауыстыру',
    name: 'Аты',
    description: 'Сипаттама',
    user: 'Пайдаланушы',
    users: 'Пайдаланушылар',
    password: 'Құпиясөз',
    history: 'Тарих',
    copy: 'Көшіру',
    copied: 'Көшірілді!',
    createdAt: 'Жасалды',
    createdBy: 'Кім жасады',
    importCSV: 'CSV импорттау',
    online: 'Онлайн',
    offline: 'Оффлайн',
    enabled: 'енгізілген',
    status: 'Мәртебе',
    from: 'бастап',
    to: 'дейін',
    importJSON: 'JSON импорттау',
    addNew: 'қосу',
    provider: 'Провайдер',
    default: 'Ұйғарым бойынша',
    service: 'Сервис',
    key: 'Кілт',
    email: 'Электрондық поштаның мекенжайы',
    updatedAt: 'Өзгертілді',
    emptyWorkspace: 'Бөлімде әлі жазбалар жазылмаған',
    emptyResultSearch: 'Іздеу еш нәтиже бермеді',
    deleteConfirmation: {
      title: 'Жоюды растаңыз',
      askingAlert: '{count} жазбасын  жойғыңыз келетініне сенімдісіз бе?  | {count} жазбасын  жойғыңыз келетініне сенімдісіз бе?',
      undoneActionAlert: 'Бұл әрекетті қайтару мүмкін емес.',
      deleteAll: 'БАРЛЫҒЫ',
    },
    CSV: {
      skipHeaders: 'Тақырыптарды жию',
      separator: 'Ажыратқыш',
      CSVColumn: 'CSV өрісі',
      fieldName: 'Өріс атауы',
      clearMember: 'Абонентті тазалау',
    },
    generalInfo: 'жалпы ақпарат',
    directory: {
      directory: 'Каталог',
      passwordInfo: 'Құпиясөзді қайта жазу (немесе жаңасын жасау) қажет болса, мәнді енгізіңіз',
      users: {
        users: 'Пайдаланушы | Пайдаланушылар',
        allUsers: 'Барлық пайдаланушылар',
        searchPlaceholder: 'Аты, пайдаланушы, телефон нөмірлері, мәртебесі',
        login: 'Логин',
        extensions: 'Ішкі нөмір',
        state: 'Мәртебе',
        status: 'Мәртебе',
        roles: 'Рөлдері',
        tokens: 'Токендер',
        usage: 'Пайдалану',
        roleAdmin: 'Әкімші',
        license: 'Лицензия',
        devices: 'Құрылғы',
        defaultDevice: 'Ұйғарым бойынша құрылғы (таңдалған құрылғылар тізімінен)',
        deviceNotFound: 'Құрылғыңызды таба алмадыңыз ба?',
        createNewDevice: 'Жаңасын қосыңыз',
        variables: 'Ауыспалы',
        varKey: 'Кілт',
        varVal: 'Мәні',
        token: 'Токен | Токендер',
        tokenPopupHeader: 'Сіздің токен-кілтіңіз сәтті жасалды!',
        tokenPopupText: 'Файлды қазір кілтпен жүктеп алыңыз немесе оны кейінірек пайдалану үшін көшіріңіз. Егер   файл кілтін қазір жүктемесеңіз,токен-кілтіңізді қайтадан ала алмайсыз. Сіздің қауіпсіздігіңіз үшін токен-кілтіңізді ешкімге бермеңіз.',
        tokenPopupCopy: 'Көшіру',
        tokenPopupSave: 'TXT форматында сақтаңыз',
      },
      license: {
        customers: 'Пайдаланушылар',
        license: 'Лицензия | Лицензиялар',
        allCustomers: 'Барлық пайдаланушылар',
        allLicenses: 'Барлық лицензиялар',
        serial: 'Серия',
        product: 'Өнім',
        validFrom: 'Бастап жарамды',
        validTill: 'дейін жарамды',
        used: 'Пайдаланылған',
        limit: 'Лимит ',
        status: 'Мәртебе',
        competitive: 'Бәсекелес',
        searchPlaceholder: 'Серия, өнім',
        domain: 'Домен',
        daysToExpire: {
          '0': 'Жарамдылық мерзімінің өтуі',
          '30': '30 немесе одан аз',
          '90': '90 немесе одан аз',
          days: 'күндер',
        },
        importLicense: 'Лицензияны жүктеп алу',
        licenseKey: 'Лицензия  кілті',
      },
      devices: {
        devices: 'Құрылғы | Құрылғылар',
        allDevices: 'Барлық құрылғылар',
        newDevice: 'Жаңа құрылғы',
        newDeviceDescription: 'Жаңа құрылғының сипаттамасы',
        hotdeskDeviceSettings: 'Құрылғының ауысымдық қолданылуын баптау',
        searchPlaceholder: 'Атауы, пайдаланушы, идентификатор & компьютер идентификаторы',
        authId: 'Идентификатор',
        presence: 'Ағымдағы мәртебесі',
        hotdesk: 'Hot desking',
        hotdeskDevice: 'Hot desking құрылғы',
        hotdeskIncorrectInput: 'Хост атауы қате',
        hotdeskInputHint: 'Хост атауы үшін дұрыс таңбаларды пайдаланыңыз. Рұқсат етілген таңбалар: A-Z, a-z, 0-9, -, _',
        deviceSettings: 'Құрылғының баптаулары',
        hostName: 'Хост атауы',
        phoneInfo: 'Телефон туралы  ақпарат',
        phone: 'Телефон ',
        vendor: 'Өндіруші',
        model: 'Моделі',
        deviceHistory: 'Пайдалдану тарихы',
        loggedIn: 'Жүйеде',
        loggedOut: 'Жүйеден шықты',
        state: {
          nonreg: 'Тіркелмеген',
          reged: 'Тіркелген',
          ringing: 'Қоңырау шалу',
          dialing: 'Теру',
          dialog: 'Әңгімелесуде',
          onhold: 'Күтуде',
        },
        passwordSetFromAccount: 'Құпиясөзді орнатпасаңыз, ол аккаунтыңызбен бірдей болады',
      },
    },
    permissions: {
      permissions: 'Рұқсат',
      permissionsRole: 'Рөл | Рөлдер',
      allRoles: 'Барлық рөлдер',
      searchPlaceholder: 'Рөл ',
      roles: {
        permissions: {
          permissions: 'Рөлге қосылу құқығы | Рөлдерге қосылу құқығы',
          add: 'Құру ',
          delete: 'Жою',
          read: 'Таңдау',
          write: 'Түзету',
          eavesdropCall: 'Белсенді қоңырауды тыңдау',
          playbackRecordFile: 'Қоңырау жазбаларын ойнату',
          exportDataGrid: 'Деректерді экспорттау',
          viewCdrPhoneNumbers: 'Шифрланбаған телефон нөмірлерін қарау',
          manageUserRoles: 'Пайдаланушы рөлдерін басқару',
          manageUserLicense: 'Пайдаланушы лицензиясын басқару',
          changeUserPassword: 'Пайдаланушың құпиясөзін өзгерту',
          addDescription: 'Нысандарды жасауға рұқсат береді',
          deleteDescription: 'Нысандарды жоюға рұқсат береді',
          readDescription: 'Нысандарды таңдауға рұқсат береді',
          writeDescription: 'Нысандарды өзгертуге рұқсат береді',
          eavesdropCallDescription: 'Белсенді әңгімені тыңдауға рұқсат береді',
          playbackRecordFileDescription: 'Сөйлесу жазбалары бар файлдарды ойнатуға рұқсат береді',
          exportDataGridDescription: 'Деректерді экспорттауға рұқсат береді',
          viewCdrPhoneNumbersDescription: 'Шифрланбаған телефон нөмірлерін көрсетуге рұқсат береді',
          manageUserRolesDescription: 'Пайдаланушы рөлдерінің жиынын басқаруға рұқсат береді',
          manageUserLicenseDescription: 'Пайдаланушы лицензияларының жинағын басқаруға рұқсат береді',
          changeUserPasswordDescription: 'Пайдаланушының құпиясөзін өзгертуге рұқсат береді',
        },
        addPermission: 'Рөлге кіру құқығын қосыңыз',
        usage: 'Пайдалану ',
        applicationsAccess: {
          applicationsAccess: 'Қосымша | Қосымшалар',
          access: 'Қол жеткізу',
        },
      },
      object: {
        object: 'Бөлімдер',
        allObjects: 'Барлық бөлімдер',
        ObAC: 'Әрекеттерді басқару',
        RbAC: 'Жазбаларды басқару',
        operations: 'Әрекеттер',
        searchPlaceholder: '... бөлім',
        newPermissionRole: 'Жаңа құқық иесі',
        rbacDefault: 'Ұйғарым бойынша жазбаларға кіру құқығы',
        grantor: 'құқық беруші',
        grantee: 'Алушы',
        create: 'Жасау',
        delete: 'Жою',
        accessMode: {
          '1': 'Тыйым салынады',
          '2': 'Рұқсат',
          '3': 'Басқару ',
        },
      },
    },
    lookups: {
      lookups: 'Анықтамалықтар',
      skills: {
        skills: 'Дағды | Дағдылар',
        capacity: 'Әлеует',
        minCapacity: 'Ең төменгі  әлеует',
        minCapacityLessOrEqualToMaxCapacityValidator: 'Ең жоғарғы әлеуеттен төмен немесе тең болуы керек',
        maxCapacity: 'Ең жоғарғы әлеует',
        maxCapacityMoreOrEqualToMinCapacityValidator: 'Ең төменгі әлеуеттен үлкен немесе тең болуы керек',
        lvl: 'Деңгей',
        agentSkills: 'Оператор дағдылары | Операторлардың дағдылары',
        allSkills: 'Барлық дағдылар',
        state: 'Жағдай',
        stateForAll: 'Барлығына арналған жағдай ',
        changeSkillTo: 'дағдыны.... ауыстыру',
        changeAgentsSkill: 'Операторлар үшін дағдыны ауыстыру',
        totalAgents: 'Барлық операторлар',
        activeSkillAgents: 'Белсенді дағдымен ',
        assignSkillToAgents: 'Операторларға дағдыны тағайындау',
        assignAgent: 'Операторды тағайындау',
        selectCapacityAndState: 'Әлеует пен жағдайды таңдаңыз',
      },
      buckets: {
        buckets: 'Себет | Себеттер',
        allBuckets: 'Барлық себеттер',
        addBucket: 'Себетті қосу',
      },
      blacklist: {
        blacklist: 'Тізім | Тізімдер',
        allBlacklists: 'Барлық тізімдер',
        number: 'Нөмір | Нөмірлер',
        newNumber: 'Жаңа нөмір',
        numbersCount: 'Нөмірлер',
        expireAt: 'Жарамдылық мерзімі',
        temporary: 'Уақытша',
      },
      media: {
        mediaFiles: 'Медиафайл| Медиафайлдар',
        allMediaFiles: 'Барлық медиафайлдар',
        createdAt: 'Жасалды',
        format: 'Формат',
        size: 'Көлемі',
        textToSpeech: 'Сөйлеу синтезі',
        popupSettings: 'Баптау ',
        lang: 'Тіл',
        voice: 'Дауыс',
        token: 'Токен',
        key: 'Кілт',
        dragPlaceholder: 'Файлдарды жүктеп салу',
        dragPlaceholderHere: 'Мұнда.',
        tts: {
          hint: 'Аудиофайл жасау',
          textType: {
            textType: 'Мәтін типі',
          },
        },
      },
      regions: {
        regions: 'Алаң | Алаңдар',
        allRegions: 'Барлық алаң',
      },
      timezone: {
        timezone: 'Уақыт белдеуі | Уақыт белдеуі',
      },
      calendars: {
        calendars: 'Күнтізбе | Күнтізбелер',
        allCalendars: 'Барлық күнтізбелер',
        searchPlaceholder: 'Атауы, сипаттамасы...',
        timezone: 'Уақыт белдеуі',
        start: 'Басы',
        end: 'Соңы',
        workWeek: 'Жұмыс аптасы',
        holidays: 'Демалыс күні | Демалыс күндері',
        date: 'Күні',
        repeat: 'Қайталау',
        fulltime: 'Уақытша',
        everyYear: 'Жыл сайын қайталау',
        newHoliday: 'Жаңа демалыс күні',
        mon: 'Дүйсенб',
        tue: 'Сейсенбі',
        wed: 'Сәрсенбі',
        thu: 'Бейсенбі',
        fri: 'Жұма',
        sat: 'Сенбі',
        sun: 'Жексенбі',
      },
      communications: {
        communications: 'Байланыс түрі| Байланыс түрлері',
        allCommunications: 'Байланыстың барлық түрлері',
        addCommunication: 'Байланысты қосу',
        code: 'Код',
        channels: {
          [EngineCommunicationChannels.Phone]: 'Телефон',
          [EngineCommunicationChannels.Email]: 'Электрондық пошта',
          [EngineCommunicationChannels.Messaging]: 'Хабарламалар',
        },
      },
      pauseCause: {
        pauseCause: 'Оператордың мәртебесі',
        allPauseCause: 'Оператордың үзілісінің барлық себептері',
        limit: 'Лимит (мин)',
        min: 'Минут',
        allowAdmin: 'Әкімші бұл мәртебені өзгерте алады',
        allowSupervisor: 'Супервизор бұл мәртебені өзгерте алады',
        allowAgent: 'Оператор бұл мәртебені өзгерте алады',
      },
    },
    routing: {
      routing: 'Бағыттау',
      protocol: 'Протокол ',
      port: 'Порт',
      configuration: 'Конфигурация',
      schema: 'Қоңырау схемасы',
      flow: {
        flow: 'Схема| Схемалар',
        allFlowSchemas: 'Барлық схемалар',
        createFlowSelectionPopup: 'Жаңа схема құру',
        createNew: 'Жаңа схема құрасыз  ба?',
        editor: 'Редактор',
        diagram: {
          diagram: 'Диаграмма',
          description: 'Webitel Flow Diagram көмегімен жаңа схема құру',
        },
        code: {
          code: 'Код',
          description: 'Код редакторында JSON схемасын пайдаланып жаңа схема құру',
        },
      },
      dialplan: {
        dialplan: 'Шығыс бағыттау',
        dialplanRules: 'Шығыс бағыттау қағидалары ',
        pattern: 'Белгіленген нөмірі',
        dialplanRule: 'Шығыс бағыттау қағидалары ',
        position: 'Позиция',
      },
      chatplan: {
        chatplan: 'Мәтіндік хабарламаны бағыттау қағидасы | Мәтіндік хабарламаны бағыттау қағидасы',
        allChatplans: 'Мәтіндік хабарламаны бағыттаудың барлық қағидасы',
      },
      gateways: {
        gateways: 'Шлюз | Шлюздер',
        allGateways: 'Барлық шлюздер',
        trunkingGateway: 'Көп арналы шлюз',
        registerGateway: 'Шлюзді тіркеу',
        stateSuccess: 'Сәтті',
        stateFailed: 'Сәтсіз',
        stateProgress: 'Орындалуда',
        stateNonreg: 'Тіркелмеген',
        proxy: 'Прокси',
        searchPlaceholder: 'шлюзді іздеу...',
        host: 'Хост',
        trunkingACLTitle: 'Қосылуды басқару',
        trunkingACL: 'Көп арналы косылу тізімі',
        hostnameTrunking: 'Сервер/Шлюздің хосты немесе IP',
        hostnameRegister: 'Тіркеуші/Сервер/Шлюз Хост немесе IP',
        expire: 'Жарамдылық мерзімі',
        authID: 'Пайдаланушының аты',
        account: 'Аккаунт',
        outboundProxy: 'Шығыс SIP прокси мекенжайы',
        newGateway: 'Жаңа шлюз',
        newGatewayDescription: 'Жаңа шлюздің сипаттамасы',
        SIPregistrations: 'SIP тіркеу',
        SIPregistrationsDescription: 'SIP тіркеуінің сипаттамасы',
        SIPtrunking: 'SIP-транктер',
        SIPtrunkingDescription: 'SIP транктерінің сипаттамасы',
      },
      chatGateways: {
        templates: {
          templates: 'Үлгілер',
          title: 'Workspaceтегі абоненттің жасырын аты ',
          close: 'Чатты аяқтау хабары',
          join: 'Оператордың қосылу хабарламасы',
          left: 'Операторды ажырату хабарламасы',
        },
        chatGateways: 'Мәтіндік шлюз | Мәтіндік шлюздер',
        allChatGateways: 'Барлық мәтіндік шлюздер',
        telegramBot: {
          telegramBot: 'Telegram Бот',
        },
        telegramApp: {
          telegramApp: 'Telegram қосымшасы',
          signedAs: 'Сіз ... авторландыңыз',
          metadata: {
            apiId: 'API идентификатор',
            apiHash: 'API хэш',
          },
        },
        infobip: {},
        messenger: {
          addOrRemovePages: 'Парақшаларды қосу немесе жою',
          accounts: 'Аккаунттар',
          metadata: {},
          facebook: {
            pages: 'Facebook парақшалары',
            subscription: 'Webhook жазылымдары',
          },
          instagram: {
            comments: 'Пікірлерді қадағалау',
            mentions: 'Ескертуді қадағалау',
          },
          whatsapp: {
            token: 'Токен',
            status: 'Мәртебе',
            number: 'Телефон нөмірі',
            review: 'Қарастыру',
          },
        },
        viber: {
          style: {
            style: 'Стиль',
            btnBackColor: 'Батырма фонының түсі',
            btnFontColor: 'Батырма мәтінінің түсі',
          },
        },
        webchat: {
          copyCode: 'Кодты көшіру',
          copyCodeHint: 'Баптауды әр өзгерткеннен кейін сайтқа Виджет кодын қайта жасау және енгізу қажет',
          metadata: {
            mediaMaxSize: 'Ең жоғарғы файл көлемі (МБ)',
          },
          view: {
            borderRadius: 'Батырма түрі',
            language: 'Тіл',
            view: 'Түрі',
            position: 'Орналастыру',
            btnColor: 'Батырманың түсі',
            logoUrl: 'Логотип URL',
            logoHint: 'Қолданылатын логотип пішімі - \'JPEG\' немесе \'PNG\', 24x24px',
            rounded: 'Дөңгелек',
            square: 'квадрат ',
            right: 'Оң жақта',
            left: 'Сол жақта',
            static: 'Статикалық',
          },
          chat: {
            chat: 'Чат',
            openTimeout: 'Ашылу кідірісін қосыңыз',
            openTimeoutSec: 'Ашылудың кешігу уақыты',
          },
          appointment: {
            appointment: 'Қоңырау шалуға тапсырыс беру',
            days: 'Күндер саны',
            availableAgents: 'Бос агенттер',
            showEmailField: 'Поштаны енгізу өрісін көрсету',
            showMessageField: 'Хабарлама енгізу өрісін көрсету',
            resultPageText: 'Нәтиже бетіндегі мәтін',
            headingText: 'Тақырып',
            subheadingText: 'Тақырыпша',
            showDefaultHeading: 'Нәтиже бетінде стандартты тақырып пен тақырыпшаны көрсетіңіз',
          },
          alternativeChannels: {
            alternativeChannels: 'Балама арналар',
            email: 'Электрондық пошта',
          },
        },
        newChatGateway: 'Жаңа мәтіндік шлюз',
        metadata: {},
      },
      callflow: {
        callflow: 'Қоңырау конструкторы',
      },
    },
    ccenter: {
      ccenter: 'Байланыс орталығы',
      agents: {
        agents: 'Оператор | Операторлар',
        supervisors: 'Супервизор | Супервизорлар',
        admins: 'Әкімші | Әкімшілер',
        subordinates: 'Бағынышты оператор | Бағынышты операторлар',
        allAgents: 'Барлық операторлар',
        state: 'Мәртебе',
        progressiveCount: 'Бір уақыттағы қоңыраулар саны',
        chatCount: 'Бір уақыттағы чаттар саны',
        isSupervisor: 'Супервизор',
        stateTime: 'Ұзақтығы',
        addSkill: 'Дағды қосу',
        addSubordinate: 'Бағынышты операторды қосу',
        statusHistory: 'Жағдайдың тарихы',
        historyState: 'Жағдай',
        historyChannel: 'Арна',
        historyFrom: 'бастап',
        historyTo: 'Дейін',
        historyDuration: 'Ұзақтығы',
        payload: 'Себеп',
        emptyPopup: 'Өтінемін, іріктеме баптауларын тексеріңіз',
        status: {
          online: 'Онлайн',
          offline: 'Оффлайн',
          pause: 'Үзіліс',
          breakOut: 'Мәжбүрлі үзіліс',
        },
      },
      auditors: {
        auditors: 'Аудитор | Аудиторлар',
      },
      teams: {
        teams: 'Команда| Командалар',
        allTeams: 'Барлық командалар',
        strategy: 'Стратегия',
        timing: 'Қоңырау шалу параметрлері',
        maxNoAnswer: 'Жауапсыз қоңыраулардың ең аз саны',
        callTimeout: 'Қоңыраудың ұзақтығы',
        inviteChatTimeout: 'Чатты қабылдау уақыты',
        wrapUpTime: 'Қоңыраулар арасындағы үзілістің ұзақтығы',
        noAnswerDelayTime: 'Оператордың жауабы болмаған кезде күту уақыты',
        strategies: {
          random: 'Кездейсоқ',
          fewestCalls: 'Ең аз қоңырау қабылдайтын агент',
          leastTalkTime: 'Сөйлесу уақыты ең қысқа агент',
          topDown: 'Жоғарыдан төмен',
          roundRobin: 'ретімен ',
          roundRobinBucket: 'Себет ретімен',
          longestIdleAgent: 'Ең көп тоқтап қалу уақыты бар агент',
          skillCapacity: 'Дағды деңгейі бойынша',
        },
      },
      members: {
        members: 'Абонент| Абоненттер',
        allMembers: 'Барлық абоненттер',
        addMember: 'Абонентті қосу',
        destination: 'Тағайындау',
        display: 'Нөмірді көрсету',
        priority: 'Басымдық',
        attempts: 'Әрекет',
        emptyWorkspace: 'Абоненттер табылмады',
        resetMembers: {
          resetMembers: 'Абоненттерді қайта іске қосыңыз',
          description: 'Сіз шынымен абоненттердің сәтсіз әрекеттері бойынша қайта қоңырау шалғыңыз келе ме? ',
          reset: 'Қайтадан қосу',
          successResetCount: '{count} абонент сәтті қайта іске қосылды',
        },
        endCause: {
          abandoned: 'Жоғалған',
          timeout: 'Тайм-аут',
          cancel: 'Бас тартылған',
          success: 'Сәтті',
          failed: 'Сәтсіз',
          missed: 'Қабылданбаған',
          expired: 'Мерзімі өткен',
        },
      },
      queues: {
        queues: 'Кезек | Кезектер',
        allQueues: 'Барлық кезектер',
        type: 'Тип',
        state: 'Жағдай',
        active: 'Белсенді',
        notActive: 'Белсенді емес',
        activeCalls: 'Белсенді қоңыраулар',
        waiting: 'Күту',
        ringtone: 'Әуен',
        priority: 'Басымдық',
        blacklist: 'Тоқтату ',
        newQueue: 'Жаңа кезек',
        newQueueDescription: 'Кезек түрлері',
        outboundIVRQueue: 'Шығыс IVR кезегі',
        outboundIVR: 'Шығыс IVR',
        outboundIVRQueueDescription: 'Шығыс IVR ұйымға жеке хабарландыруларды қолдана отырып, автоматты дыбыстық қоңыраулар, SMS хабарламалар, электрондық пошта немесе әлеуметтік желілердегі хабарламалар сияқты бірнеше арна арқылы клиенттерді автоматты түрде тартуға мүмкіндік береді.',
        inboundQueue: 'Кіріс кезегі',
        inboundQueueDescription: 'Кіріс кезегі: Қоңырау шалушылар оператормен сөйлесу үшін кезекте күтеді.',
        offlineQueue: 'Оффлайн кезек',
        offlineQueueDescription: 'Автономды кезек қоңырау шалушыларға күтуді тоқтатуға, телефон нөмірін енгізуге және кері қоңырау шалуға мүмкіндік береді.',
        previewDialer: 'Қоңырауды алдын ала қарау',
        previewDialerDescription: 'Қоңырау бастамашысы - оператор. Нөмірді автоматты түрде теру алдында байланысты алдын ала қарау. ',
        progressiveDialer: 'Прогрессивті қоңырау шалу',
        progressiveDialerDescription: 'Операторды резервтеу арқылы шығыс науқан.',
        predictiveDialer: 'Болжамды қоңырау',
        predictiveDialerDescription: 'Қоңырауды күту уақытын барынша азайту үшін операторды алдын ала резервтеусіз шығыс науқаны.',
        chatInboundQueue: 'Кіріс чат кезегі',
        chatInboundQueueDescription: 'Кіріс кезегі сияқты, бірақ чаттармен',
        inboundJobQueue: 'Кіріс жұмыс кезегі',
        inboundJobQueueDescription: 'Кіріс жұмыс кезегі',
        outboundJobQueue: 'Шығыс жұмыс кезегі',
        outboundJobQueueDescription: 'Шығыс жұмыс кезегі',
        strategy: 'Стратегия',
        timezone: 'Уақыт белдеуі',
        callLimit: 'Бір уақыттағы қоңыраулардың ең көп саны',
        variables: 'Ауыспалы',
        members: 'Абонент |Абоненттер',
        params: 'Қоңырау шалу параметрлері',
        discardAbandonedAfter: 'Абонент толық өшкенге дейінгі секундтар саны',
        maxIdleAgent: 'Оператордың жауабы күтілуде',
        maxIdleClient: 'Оператордың жауабы күтілуде',
        maxIdleDialog: 'Хабарламаны максималды күту уақыты',
        timeoutWithNoAgents: 'Агенттер болмаған кезде күту уақыты',
        timeBaseScore: 'Қоңырау рейтингі',
        endless: 'Қоңырауды тоқтатпау',
        stickyAgent: 'Жабысқақтық',
        stickyAgentSec: 'Жабысқақты күту уақыты (сек)',
        autoAnswerTone: 'Автоматты жауап беру туралы ескерту сигналы',
        varKey: 'Кілт',
        varVal: 'Мағынасы',
        endCause: 'Тоқтату себебі',
        offeringAt: 'Қайта қоңырау шалу',
        destination: 'Тағайындау',
        expire: 'аяқталады',
        originateTimeout: 'Күту уақыты',
        maxAgentLine: 'Операторға бір мезгілде соғылған қоңыраулар саны',
        maxAgentLose: 'Желілер терілгенге дейін тоқтату үшін қабылданбаған қоңыраулардың ең көп саны',
        minAttempts: 'Болжамды түзімге ауысу әрекеттерінің саны',
        maxAbandonedRate: 'Қабылданбаған қоңыраулардың рұқсат етілген %',
        loadFactor: 'Жүктеме коэффициенті',
        abandonRateAdjustment: 'Қабылданбаған қоңырауларды түзету',
        playbackSilence: 'Аудио файлды ойнату алдында дыбысты өшіру (мс.)',
        targetAbandonedRate: 'Қабылданбаған қоңыраулардың қалаулы  %',
        maxWaitTime: 'Ең ұзақ күту уақыты',
        maxWaitingSize: 'Күту уақытының ұзақтығы',
        waitBetweenRetries: 'Әрекеттер арасындағы уақыт',
        waitBetweenRetriesDesc: 'Жаңа әрекеттерді кему реті бойынша іріктеу',
        retryAbandoned: 'Жоғалған абоненттерді қалпына келтіру',
        playbackFile: 'Файлды ойнату',
        timeout: 'Қоңыраулар арасындағы үзіліс уақыты',
        maxNumberOfRetry: 'Қоңырау шалу әрекеттерінің ең көп саны',
        minDuration: 'Ең аз сәтті қоңыраудың ұзақтығы',
        maxAttempts: 'Әрекеттердің ең көп саны',
        waitForResultStatus: 'Қоңырау нәтижесін күтуде',
        bucketPriority: 'Басымдық',
        enabled: 'Автоматты анықтауды қосыңыз',
        ai: 'Жасанды интеллект',
        positiveLabels: 'Оң тегтер',
        aiPlayback: 'Файлды ойнату',
        allowNotSure: 'Белгісіз',
        silenceNotSure: 'Тыныштық',
        allowGreetingAgent: 'Амандасуға рұқсат ',
        preSchema: 'Алдын ала теру схемасы',
        afterSchema: 'Теруден  кейінгі схема',
        maxCalls: 'Тапсырмалар шегі',
        maxCallsHint: 'Мәні 0 -ге тең болса, дайлер қоңырау шалмайды',
        recordings: 'Жазба',
        recordAll: 'Жазуды жауап бергеннен кейін бастау',
        perNumbers: 'Әрбір нөмір үшін бастама',
        maxWordLength: 'Сөйлемнің максималды ұзақтығы (мс)',
        maxNumberOfWords: 'Сәлемдесудегі сөздердің ең аз саны',
        betweenWordsSilence: 'Сөздер арасындағы үнсіздіктің ұзақтығы (мс)',
        minWordLength: 'Дауыстағы үздіксіз дыбыстың ең аз ұзақтығы (мс)',
        totalAnalysisTime: 'Таныудың ең көп уақыты (мс)',
        silenceThreshold: 'Сөздер арасындағы үнсіздіктің максималды ұзақтығы (мс)',
        afterGreetingSilence: 'Сәлемдесуден кейінгі үнсіздік ұзақтығы (мс)',
        greeting: 'Максималды сәлем ұзақтығы (мс)',
        initialSilence: 'Сәлемдесу алдындағы үнсіздіктің максималды ұзақтығы (мс)',
        statisticTime: 'Қайта есептеу аралығы',
        communications: 'Байланыс түрі',
        processing: {
          processing: 'Өңдеу',
          enabled: 'Тапсырма нәтижесін күту',
          formSchema: 'Динамикалық өңдеу схемасы',
          sec: 'Өңдеу уақыты (сек)',
          renewalSec: '(сек) кейін ұзартуды ұсыну',
        },
        queueStrategy: {
          fifo: 'FIFO (Бірінші кірді Бірінші шықты)',
          lifo: 'LIFO (Соңғысы кірді Бірінші шықты)',
          strict_fifo: 'StrictFIFO (Бірінші кірді Бірінші шықты)',
        },
        time: {
          t15m: '15 мин',
          t30m: '30 мин',
          t45m: '45 мин',
          t1h: '1 сағат',
          t3h: '4 сағат',
          t6h: '6 сағат',
        },
        logs: {
          logs: 'Лог | Логтар',
          destination: 'Тағайындау',
          offeringAt: 'Тарату',
          joinedAt: 'Басы',
          leavingAt: 'Соңы',
          duration: 'Ұзақтығы',
          viewNumber: 'Нөмір',
          result: 'Нәтиже',
          attempts: 'Әрекет',
          resultName: {},
        },
        hooks: {
          hooks: 'Хук | Хуктар',
          event: 'Оқиға',
          eventTypes: {
            joined: 'Абонент кезекке тұрды',
            answered: 'Оператор қабылдады',
            offering: 'Операторға бөліп беру',
            bridged: 'Абонентті оператормен қосу',
            missed: 'Оператор жіберіп алды',
            leaving: 'Абонент кезектен шықты',
            processing: 'Кейінгі өңдеу',
            active: 'Белсенді әрекет',
          },
        },
      },
      res: {
        res: 'Ресурс | Ресурстар',
        allRes: 'Барлық ресурстар',
        reserve: 'Резервтік ',
        searchPlaceholder: 'атауы, шлюз...',
        limit: 'Лимит ',
        numbers: 'Нөмір | Нөмірлер',
        displayNumbers: 'Көрсетілетін нөмір',
        failure: 'Іркіліс',
        maxErrors: 'Қателердің максималды саны',
        errorCodes: 'Қателер кодтары',
        patterns: 'Шығыс нөмір шаблоны',
        priority: 'Басымдық',
        reserveResource: 'Резервтік ресурс',
        failureDialDelay: 'Қате теру кезіндегі кідіріс',
      },
      resGroups: {
        resGroups: 'Ресурстар тобы | Ресурстар топтары ',
        allResGroups: 'Барлық ресурстар топтары',
        searchPlaceholder: 'ресурс топтарының атауы..',
        addResGroup: 'Ресурстар тобын қосу',
        strategy: 'Стратегия',
        timerange: 'Аралық',
        timerangeFrom: 'Бастап',
        timerangeTo: 'Дейін',
      },
    },
    integrations: {
      integrations: 'Интеграция',
      emptyWorkspace: 'Бөлімдегі профильдер әлі жасалмаған',
      storage: {
        storage: 'Сақтау орны',
        allStorages: 'Барлық сақтау орындары',
        type: 'Тип',
        maxSize: 'Сақтау орнының көлемі (GB)',
        expireDays: 'Сақтау мерзімі',
        priority: 'Басымдық',
        newStorage: 'Жаңа сақтау орны',
        newStorageDescription: 'Жаңа сақтау орнының сипаттамасы',
        configuration: 'Конфигурация',
        pathPattern: 'Жүру шаблоны',
        bucket: 'Себет',
        region: 'Алаң',
      },
      cognitiveProfiles: {
        cognitiveProfiles: 'Дыбыстық профиль | Дыбыстық профильдер',
        properties: {
          region: ' Аймақ',
          locale: 'Тіл',
        },
      },
      emailProfiles: {
        emailProfiles: 'Email профилі | Email профильдері',
        mailbox: 'Пошта жәшігі',
        fetchInterval: 'Жаңарту аралығы',
      },
      importCsv: {
        importCsv: 'CSV файлынан деректерді импорттау | CSV файлдарынан деректерді импорттау',
        allImportsCsv: 'CSV файлдарынан импортталған барлық деректер ',
        source: 'Импорттауды тағайындау',
        settings: 'Баптау',
        mappings: 'Өрістер маппингі',
        mappingsHint: 'Бұл бөлімде CSV файлының бағандарының атауларын төменгі өрістерде олардың тақырыптарына сәйкес көрсету қажет',
        columnHeader: 'Баған',
        members: {
          clearMembers: 'Абоненттерді  жою',
        },
      },
      tokens: {
        tokens: 'Токен | Токендер ',
        allTokens: 'Барлық токендер ',
        expires: 'Мерзімі аяқталады',
        popupMessage: 'Кілт файлын жүктеп алыңыз немесе оны көшіріп алыңыз. Кілт файлын қазір жүктеп алмасаңыз, оны қайтадан шығарып ала алмайсыз. Қауіпсіздікті арттыру үшін кілтті қауіпсіз жерде сақтаңыз және оны ешкімге бермеңіз',
        tokenCreated: 'Сіздің токеніңіз сәтті жасалды',
      },
      triggers: {
        triggers: 'Триггер | Триггерлер',
        allTriggers: 'Барлық триггерлер',
        type: 'Тип',
        schema: 'Схема',
        newTrigger: 'Жаңа триггер',
        expression: 'Мән',
        timeout: 'кідіру',
        logs: {
          logs: 'Басталу тарихы',
          startedAt: 'Басы',
          result: 'Нәтиже',
          resultName: {
            idle: 'Белсенді емес',
            active: 'Белсенді',
            done: 'Аяқталған',
            error: 'Қате',
          },
        },
      },
    },
    pagination: {
      rowsPerPage: 'Бір беттегі жолдар саны',
    },
  },
  utils: {
    downloadFilesBtn: {
      downloadFiles: 'Файлдарды жүктеп алу',
      filesLoaded: 'жүктелген файлдар',
      zippingProgress: 'Мұрағаттау прогресі',
    },
  },
  errorPages: {
    goToHome: 'Басына оралу',
    page403: {
      title: 'Кіру мүмкіндігі жоқ',
      text: 'Кешіріңіз, сіздің бұл бетті қарауға құқығыңыз жоқ',
    },
    page404: {
      title: 'Сіз адасып кеткен сияқтысыз',
      text: 'Кешіріңіз, біз сіз іздеген мәліметті таба алмаймыз',
    },
  },
  icon: {
    docs: 'Құжаттама',
    logout: 'Шығу',
    account: 'Аккаунт',
    appNavigator: 'Қосымшалар навигаторы',
    settings: 'Баптау',
  },
  iconHints: {
    upload: 'Жүктеу',
    reload: 'Жаңарту',
    edit: 'Өңдеу',
    delete: 'Жою',
    deleteAll: 'Барлық нысандарды жою',
    deleteSelected: '{count} таңдалған нысандарды жою ',
    deleteFiltered: 'Барлық іріктелген нысандарды жою',
    generate: 'Жасау',
    add: 'Қосу',
    history: 'Тарих',
    download: 'Жүктеу',
    downloadAll: 'Бәрін жүктеу',
    draggable: 'Жылжытып апару',
    play: 'Ойнау',
    pause: 'Үзіліс',
    resume: 'Қайтадан жалғастыру',
    close: 'Жабу',
    volume: 'Дыбыс деңгейі',
    stop: 'Тоқтату',
    members: 'Абоненттер',
    moveUp: 'Жоғарыға жылжыту',
    moveDown: 'Төменге жылжыту',
    nextPage: 'Келесі бет',
    prevPage: 'Алдыңғы бет',
    expand: 'Ашу',
    collapse: 'Жабу',
  },
  errors: {
    invalidJson: 'Жарамсыз JSON',
  },
};
